<template>
  <div class="page">
    <div class="pageMain">
      <div class="tableTopBtn">
        <!-- <el-button 
          @click="addGoods" 
          type="primary" 
          class="el-button--mini" 
          :disabled="alertTlt == '编辑退货单' && formData.isTopCarriage == 2">
          <i class="el-icon-plus"></i>新增商品
        </el-button> -->
        <!-- <el-button 
          size="mini" 
          type="danger" 
          @click="delGoods" 
          :disabled="goodsIndexs.length ==formData.purchaseChildReturnOrderList.length ||goodsIndexs.length == 0" 
          class="el-icon-delete">删除商品
        </el-button> -->
        <el-button size="mini" type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')"
          :disabled="alertTlt != '编辑退货单' || formData.status == 9">保存
        </el-button>
        <el-button size="mini" type="primary" :loading="submitBtnLoad" :disabled="alertTlt != '新增退货单' || formData.status == 9"
          @click="handleSubmit('formData')">保存并新增
        </el-button>
      </div>
      <el-form :model="formData" :inline="true" :rules="addRules" ref="formData" label-width="100px"
        class="demo-ruleForm text-center">
        <el-form-item label="仓库：" prop="warehouseId">
          <el-select v-model="formData.warehouseId" :disabled="
            alertTlt == '退货单详情' || formData.isTopCarriage == 2
          " @change="changeWarehouse($event)" filterable placeholder="请选择仓库" style="width: 150px">
            <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退货人：" prop="buyer">
          <!-- <el-input v-model="formData.buyer" :disabled="
            alertTlt == '编辑退货单' && formData.isTopCarriage == 2
          " placeholder="请输入退货人" style="width: 150px"></el-input> -->
          <el-select v-model="formData.buyer" :disabled="
            alertTlt == '退货单详情' || formData.isTopCarriage == 2
          " filterable placeholder="请选择退货人" style="width: 150px">
            <el-option v-for="(item, index) in buyerList" :key="index" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运费(元)：">
          <el-input v-model.number="formData.freight" :disabled="
            alertTlt == '退货单详情' || formData.isTopCarriage == 2
          " @input="
  formData.freight = String(formData.freight).replace(
    /[^\d]/g,
    ''
  )
" type="tel" placeholder="请输入运费" style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item label="付款状态：">
          <el-select v-model="formData.isPay" filterable :disabled="
            alertTlt == '退货单详情' || formData.isTopCarriage == 2
          " placeholder="请选择付款状态" style="width: 150px">
            <el-option v-for="(item, index) in isPayList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="到货时间：" prop="arrivalTime">
          <el-date-picker v-model="formData.arrivalTime" :disabled="
            alertTlt == '退货单详情' || formData.isTopCarriage == 2
          " value-format="yyyy-MM-dd" type="date" placeholder="请选择到货时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="userTable goodsTable">
        <template>

          <!-- <div class="tip">
            <p>*注释：</p>
            <p>
              1、规格比是商品规格与商品最小单位的比例，例：商品规格为10斤/件，则规格比为1:10；
            </p>
            <p>2、规格比用于商品的拆卸单，若商品需要拆分时必须填写；</p>
          </div> -->

          <purchase-table ref="purchaseTable" modelType="退货单" :type="pageFlag" :supplierList="supplierList" :formData="formData"
            @changeGoods="addGoods"></purchase-table>

        </template>
      </div>
    </div>

  </div>
</template>
<script>
import Vue from "vue";

import purchaseDetail from "../components/purchaseDetail.vue";
import purchaseTable from "../components/purchaseTable.vue";
export default {
  data() {
    return {
      detailFlag: false, //详情弹框
      dialogData: {}, //详情信息
      dataTemplate: {
        //数据模板
        index: 0,
        color: "", //颜色
        describe: "", //商品描述
        distributorId: "", //供应商ID
        distributorName: "", //供应商名字
        goodsId: "", //商品ID
        goodsName: "", //商品名称
        model: "", //型号
        num: "", //退货数量
        price: "", //退货单价
        purchaseOrderId: "", //退货订单ID
        remark: "", //备注
        sale: "", //销售单价
        standard: "", //规格
        totalPrice: 0, //退货总价
        unit: "", //单位名称
      },
      formData: {
        buyer: "", //退货人,
        isPay: "", //付款状态,
        isTopCarriage: "", //上架状态
        status: "", //审核状态
        totalPrice: 0, //退货总价
        totalNum: 0,
        warehouseId: "", //仓库ID
        warehouseName: "", //仓库名称
        arrivalTime: "", //到货时间
        freight: "", //运费
        remark: "", //失败原因
        purchaseChildReturnOrderList: [
          {}, {}, {}, {}, {}
        ], //商品信息
      },
      submitBtnLoad: false,
      addRules: {
        warehouseId: [
          { required: true, message: "请选择仓库", trigger: "blur" },
        ],
        buyer: [
          { required: true, message: "请输入退货人名称", trigger: "blur" },
        ],
        freight: [{ required: true, message: "请输入运费", trigger: "blur" }],
        isPay: [{ required: true, message: "请选择付款状态", trigger: "blur" }],
        arrivalTime: [
          { required: true, message: "请选择到货时间", trigger: "blur" },
        ],
      },
      isPayList: [
        //付款状态
        {
          name: "未付款",
          id: 0,
        },
        {
          name: "已付款",
          id: 1,
        },
      ],
      isTopCarriageList: [
        //上架状态
        {
          name: "未上架",
          id: 0,
        },
        {
          name: "已上架",
          id: 1,
        },
        {
          name: "已下架",
          id: 2,
        },
      ],
      statusList: [
        //审核状态
        {
          name: "待审核",
          id: 0,
        },
        {
          name: "审核通过",
          id: 1,
        },
        // {
        //   name: "审核拒绝",
        //   id: 2,
        // },
      ],
      warehouseList: [], //仓库列表
      supplierList: [], //供应商列表
      goodsDialogShow: false, //选择商品弹框
      loading: true,
      alertTlt: "",
      goodsIndex: "", //选择商品的index,用来判断是不是修改
      tableData: [],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      multipleTable: [],
      ids: [],
      goodsIndexs: [], //选择准备删除的商品
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
      approvalButton: "", //审核
      underButton: "", //下架
      topButton: "", //上架
      // 商品单位
      goodsUnit: [],
      // 退货人
      buyerList: [],
      // 1新增 2编辑 3详情
      pageFlag:null,
    };
  },
  components: {
    purchaseDetail,
    purchaseTable,
  },
  created() {
    this.getSupplierList();
    this.getWarehouse();
    this.getGoodsUnit();
    this.getBuyer();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "purchase_return_order_update") {
        this.updateButton = "1";
      } else if (item.authority == "purchase_return_order_query") {
        this.searchButton = "1";
      } else if (item.authority == "purchase_return_order_add") {
        this.addButton = "1";
      }
    });
  },
  // keep-alive组件缓存生命周期
  activated() {
    let that = this;
    let data = this.$route.params ? this.$route.params : this.$route.query
    if (data.type == 'edit') {
      this.alertTlt = "编辑退货单";
      this.pageFlag = 2
      this.$axios
        .get(this.$axios.defaults.basePath + "/purchaseReturnOrder/view", {
          params: { id: data.id },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            let data = res.data.data;
            data.totalPrice = Number(data.totalPrice);
            data.totalNum = 0;
            for (let i in data.purchaseChildReturnOrderList) {
              data.totalNum += Number(data.purchaseChildReturnOrderList[i].num);
              data.purchaseChildReturnOrderList[i].index = i;
              data.purchaseChildReturnOrderList[i].type = 'edit'
            }
            that.formData = data;
            console.log(that.formData)
          }
        });
    } else if (data.type == 'details') {
      this.alertTlt = '退货单详情'
      this.pageFlag = 3
      this.$axios
        .get(this.$axios.defaults.basePath + "/purchaseReturnOrder/view", {
          params: { id: data.id },
        })
        .then(function (res) {
          console.log(res)
          if (res.data.errcode == 0) {
            let data = res.data.data;
            if (res.data.data) {
              data.totalPrice = Number(data.totalPrice);
              data.totalNum = 0;
              for (let i in data.purchaseChildReturnOrderList) {
                data.totalNum += Number(data.purchaseChildReturnOrderList[i].num);
                data.purchaseChildReturnOrderList[i].index = i;
                data.purchaseChildReturnOrderList[i].name = data.purchaseChildReturnOrderList[i].goodsName
              }
              that.formData = data;
              console.log(that.formData)
            } else {
              that.$message({
                message: res.data.data,
                type: 'warning'
              })
            }

          }
        });
    } else {
      this.alertTlt = '新增退货单'
      this.pageFlag = 1
      if(this.$route.params.data){
        console.log(this.$route.params.data)
        this.purchaseOrderId = JSON.parse(this.$route.params.data).id
      }
    }
  },
  mounted() {
    let that = this;
    let data = this.$route.params ? this.$route.params : this.$route.query
    if (data.type == 'edit') {
      this.alertTlt = "编辑退货单";
      this.pageFlag = 2
      this.$axios
        .get(this.$axios.defaults.basePath + "/purchaseReturnOrder/view", {
          params: { id: data.id },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            let data = res.data.data;
            data.totalPrice = Number(data.totalPrice);
            data.totalNum = 0;
            for (let i in data.purchaseChildReturnOrderList) {
              data.totalNum += Number(data.purchaseChildReturnOrderList[i].num);
              data.purchaseChildReturnOrderList[i].index = i;
              data.purchaseChildReturnOrderList[i].type = 'edit'
            }
            that.formData = data;
            console.log(that.formData)
          }
        });
    } else if (data.type == 'details') {
      this.alertTlt = '退货单详情'
      this.pageFlag = 3
      this.$axios
        .get(this.$axios.defaults.basePath + "/purchaseReturnOrder/view", {
          params: { id: data.id },
        })
        .then(function (res) {
          console.log(res)
          if (res.data.errcode == 0) {
            let data = res.data.data;
            if (res.data.data) {
              data.totalPrice = Number(data.totalPrice);
              data.totalNum = 0;
              for (let i in data.purchaseChildReturnOrderList) {
                data.totalNum += Number(data.purchaseChildReturnOrderList[i].num);
                data.purchaseChildReturnOrderList[i].index = i;
                data.purchaseChildReturnOrderList[i].name = data.purchaseChildReturnOrderList[i].goodsName
              }
              that.formData = data;
              console.log(that.formData)
            } else {
              that.$message({
                message: res.data.data,
                type: 'warning'
              })
            }

          }
        });
    } else {
      this.alertTlt = '新增退货单'
      this.pageFlag = 1
       if (this.$route.params.data) {
        this.pageFlag = 3
        let newData = JSON.parse(this.$route.params.data.replace('purchaseChildOrderList', 'purchaseChildReturnOrderList'))
        // newData.id = ''
        console.log(newData)
        this.formData = newData
        this.$set(this.formData, 'purchaseOrderId', newData.id)
        this.$set(this.formData, 'id','')
      }
    }
  },
  methods: {
    // 获取退货人列表
    getBuyer() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysDictionaries/selectByList?type=1")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.buyerList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取商品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //新增/编辑退货单   多选框禁用
    addSelectable(row, index) {
      if (this.pageFlag == 2 && this.formData.isTopCarriage == 2) {
        return false;
      } else {
        return true;
      }
    },
    //退货单列表   多选框禁用
    selectable(row, index) {
      if (row.status == 1) {
        return false;
      } else {
        return true;
      }
    },

    //添加 选择仓库
    changeWarehouse(e) {
      for (let i in this.warehouseList) {
        if (this.warehouseList[i].id == e) {
          this.formData.warehouseName = this.warehouseList[i].name;
        }
      }
    },
    //子组件传过来的取消选择商品弹框事件
    cancelDialog() {
      this.goodsDialogShow = false;
    },
    //选择商品
    changeGoods(index) {
      if (this.pageFlag == 2 && this.formData.isTopCarriage == 2) {
        return;
      } else {
        this.goodsIndex = index + 1;
        this.goodsDialogShow = true;
      }
    },
    // get 供应商
    getSupplierList() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/supplier/getSupplier", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.supplierList = res.data.data;
          }
        });
    },
    //GET 仓库
    getWarehouse() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.warehouseList = res.data.data;
          }
        });
    },
    //获取总数据
    addGoods(data, keyName) {
      console.log(data, keyName)
      if (keyName == 'youhui') { //优惠金额数据
        this.$set(this.formData, 'preferentialRate', data.preferentialRate)
        this.$set(this.formData, 'preferentialAmount', data.preferentialAmount)
        this.$set(this.formData, 'discountedAmount', data.discountedAmount)
      } else if (keyName == 'sumAmount') { //退货总价
        this.$set(this.formData, 'totalPrice', data)
      }

    },
    //添加退货单 商品列表左侧复选框选择
    handleSelectionChangeGoods(val) {
      var index = [];
      for (let i in val) {
        index.push(Number(val[i].index));
      }
      this.goodsIndexs = index;
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    handleSubmit(formData) {
      var _this = this;
      let api = "";
      if (_this.pageFlag == 2) {
        api = "/purchaseReturnOrder/update";
      } else {
        api = "/purchaseReturnOrder/add";
      }

      this.$refs[formData].validate((val) => {
        if (val) {
          if (_this.formData.purchaseChildReturnOrderList.length == 0) {
            this.$message({
              message: "最少选择一种商品！",
              type: "error",
            });
            return;
          }
          _this.submitBtnLoad = true;
          // 获取子组件商品数据
          let tableData = this.$refs.purchaseTable.tableData
          let arr = [];
          tableData.forEach(item => {
            if (item.goodsName) {
              item.num = Number(item.purchaseNum)
              item.price = Number(item.purchasePrice)
              if (_this.pageFlag == 1){
                item.goodsId = item.id
                item.id = ''
              }
              arr.push(item)
            }
          })
          this.$set(this.formData, 'purchaseChildReturnOrderList', arr)

          this.$axios({
            url: _this.$axios.defaults.basePath + '/purchaseReturnOrder/checkNum',
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: _this.formData,
          }).then(function (res) {
            if (res.data.errcode == 1) {
              _this.$confirm(res.data.errmsg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                  _this.$axios({
                  url: _this.$axios.defaults.basePath + api,
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: _this.formData,
                }).then(function (res) {
                  if (res.data.errcode == 0) {
                    _this.submitBtnLoad = false;
                    _this.$message({
                      message: "操作成功",
                      type: "success",
                    });
                    _this.formData = {};
                    _this.$router.push({ path: '/warehousingReturnList' });
                  } else {
                    _this.submitBtnLoad = false;
                    _this.$message.error(res.data.errmsg);
                  }
                }).catch(() => {
                  _this.submitBtnLoad = false;
                });
              }).catch(() => {
                _this.$message({
                  type: 'info',
                  message: '已取消操作'
                });  
                _this.submitBtnLoad = false;
              });
            }else{

              _this.$axios({
                  url: _this.$axios.defaults.basePath + api,
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: _this.formData,
                }).then(function (res) {
                  if (res.data.errcode == 0) {
                    _this.submitBtnLoad = false;
                    _this.$message({
                      message: "操作成功",
                      type: "success",
                    });
                    _this.formData = {};
                    _this.$router.push({ path: '/warehousingReturnList' });
                  } else {
                    _this.submitBtnLoad = false;
                    _this.$message.error(res.data.errmsg);
                  }
                }).catch(() => {
                  _this.submitBtnLoad = false;
                });
            }
          })
        } else {
          return false;
        }
      });
    },
    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
    //删除商品
    delGoods() {
      let indexs = this.goodsIndexs.sort(function (a, b) {
        return b - a;
      });
      for (let i in indexs) {
        this.formData.purchaseChildReturnOrderList.splice(indexs[i], 1);
      }
      this.$refs.multipleTable1.clearSelection();
      this.goodsIndexs = [];
      for (let i in this.formData.purchaseChildReturnOrderList) {
        this.formData.purchaseChildReturnOrderList[i].index = i;
        Vue.set(
          this.formData.purchaseChildReturnOrderList,
          i,
          this.formData.purchaseChildReturnOrderList[i]
        );
      }
    },
    // 规格比修改
    changeScaledValue(row, e, name) {
      if (!e || e <= 0) {
        this.$message({
          message: "请输入大于0的数值",
          type: "error",
        });
        row[name] = "";
        return;
      } else {
        row[name] = e;
      }
    },
  },
};
</script>
<style scoped>
.tip {
  color: sandybrown;
  line-height: 1.5rem;
  margin-top: 0.9375rem;
  padding-left: 1.25rem;
}
</style>